.footerPhone {
  background-color: black;
  width: 100%;
  height: 10%;
  padding-top:2%;
  padding-bottom:2%;
  color: white;
  align-items: center;
  justify-content: center;
  text-align: left;
  margin-top: 5%;
}

.footertext {
  font-size: 14px;
}
