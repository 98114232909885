@font-face {
    font-family: 'FontStyleCute';
    src: url('../font/HanyiSentyFountainPen.ttf') format('truetype');
  }
@font-face {
  font-family: 'FontStyleNormal';
  src: url('../font/SentyVanilla.ttf') format('truetype');
}
@font-face {
  font-family: 'FontMiddle';
  src: url('../font/HanyiSentyChalk 2018.ttf') format('truetype');
}


.contentFontCute {
    font-family: 'FontStyleCute', sans-serif;
    font-size: 24px;  
    font-weight: 500;
}

.contentFontNormal {
  font-family: 'FontStyleNormal', sans-serif;
}

.contentFontBigTitle {
  font-family: 'FontStyleCute', sans-serif;
  font-size: 130px;  
}

.contentFontMiddle {
  font-family: 'FontMiddle', sans-serif;
  font-size: 40px;  
}

.contentFontSmall {
  font-family: 'FontStyleNormal', sans-serif;
  font-size: 20px;  
}

.contentFontCutePhone {
  font-family: 'FontStyleCute', sans-serif;
  font-size: 35px;  
  font-weight: 500;
}

.contentFontCutePhone2 {
  font-family: 'FontStyleCute', sans-serif;
  font-size: 17px;  
  font-weight: 500;
}

.fontSpacing {
  line-height: 2;
  letter-spacing: 2px;
}